#container--public-layout {
    display: grid;
    max-height: 100vh;
    grid-template-columns: 1fr 1fr;
}

#container--private-holder {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-rows: auto auto;
    padding-left: 75px;
    padding-top: 80px;
}

#background--public {
    height: 100%;
    width: 100%;
    background-color: #CED2FF;
    opacity: 0.2;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 1;
}

.container--padding {
    padding: 0px 48px;
}
.container--padding-no-left {
    padding: 0px 48px;
    padding-left: 0px;
}
.container--padding-left {
    padding-left: 48px;
}

.container--padding-right {
    padding-right: 48px;
}

.container--padding {
    display: block;
 }

 .container--padding--card-holder {
    display: block;
 }
 .container--padding--card-holder > div {
    display: block;
    width: initial;
 }

@media (min-width: 1280px) {
    .container--padding {
        padding: 0% 64px;
     }

     .container--padding-left {
        padding-left: 64px;
    }
    
    .container--padding-right {
        padding-right: 64px;
    }
    
}

@media (min-width: 1441px) {
    .container--padding--card-holder {
        display: flex;
        justify-content: center;
     }
     .container--padding--card-holder > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1244px;
    }
    .container--padding-left {
        padding-left: 0px;
    }
    
    .container--padding-right {
        padding-right: 0px;
    }
}

@media (min-width: 1800px) { 
    #container--private-holder {
        width: 100%;
        padding-left: 300px;
    }

    .container--padding--card-holder {
        display: flex;
        justify-content: center;
     }
     .container--padding--card-holder > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1222px;
     }

    .container--padding {
        padding: 0% 120px !important;
    }

    .container--padding-left {
        padding-left: 0px;
    }
    
    .container--padding-right {
        padding-right: 0px;
    }
 }

 @media (max-width: 500px) { 
    #container--public-layout {
        grid-template-columns: 1fr;
    }
 }